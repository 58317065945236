import {
  POST_LOGIN_REQUEST,
  POST_LOGIN_SUCCESS,
  POST_LOGIN_FAILURE,
  POST_REGISTER_REQUEST,
  POST_REGISTER_SUCCESS,
  POST_REGISTER_FAILURE,
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILURE,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  GET_AGENT_REQUEST,
  GET_AGENT_SUCCESS,
  GET_AGENT_FAILURE,
} from "./action";
import { ShowSnackbar } from "./snackbar";
import axios from "../axiosConfig";

import { LoginRequest } from "../interfaces/interface";
import { NavigateFunction } from "react-router-dom";
import { login, home, account } from "../constant";
import { error } from "console";

const USER_ENDPOINT = "/api/User";

export const Login = (
  loginRequest: LoginRequest,
  navigate: NavigateFunction,
  isFromRegisterPage: boolean = false
) => {
  return (dispatch: any) => {
    dispatch({
      type: POST_LOGIN_REQUEST,
      payload: { isLoadingLogin: true },
    });
    axios
      .post(USER_ENDPOINT + "/Login", loginRequest)
      .then((res) => {
        dispatch({
          type: POST_LOGIN_SUCCESS,
          payload: { isLoadingLogin: false },
        });

        const sessionData = {
          userId: res?.data?.data?.userId,
          accessToken: res?.data?.data?.accessToken,
        };
        localStorage.setItem("currentUser", JSON.stringify(sessionData));
        dispatch(ShowSnackbar("Login Successfully!", "success"));
        if (isFromRegisterPage) {
          navigate(account);
        } else {
          navigate(home);
        }
      })
      .catch((err) => {
        dispatch({
          type: POST_LOGIN_FAILURE,
          payload: { isLoadingLogin: false },
        });
        dispatch(ShowSnackbar(err?.data?.Message, "error"));
      });
  };
};

export const Register = (registerRequest: any, navigate: NavigateFunction) => {
  return (dispatch: any) => {
    const loginRequest = {
      name: registerRequest.name,
      password: registerRequest.password,
    };

    dispatch({
      type: POST_REGISTER_REQUEST,
      payload: { isLoadingRegister: true },
    });

    axios
      .post(USER_ENDPOINT + "/Create", registerRequest)
      .then((res) => {
        dispatch({
          type: POST_REGISTER_SUCCESS,
          payload: { isLoadingRegister: false },
        });
        dispatch(Login(loginRequest, navigate, true));
      })
      .catch((err) => {
        dispatch({
          type: POST_REGISTER_FAILURE,
          payload: { isLoadingRegister: false },
        });
        dispatch(ShowSnackbar(err?.data?.Message, "error"));
      });
  };
};

export const GetUserInfo = () => {
  return (dispatch: any) => {
    const localStorageUser = isUserExistInLocalStorage();
    if (localStorageUser?.userId) {
      axios
        .get(USER_ENDPOINT + "/" + localStorageUser?.userId)
        .then((res) => {
          dispatch({
            type: GET_USER_INFO_SUCCESS,
            payload: { isLoadingGetUserInfo: false, userInfo: res?.data?.data },
          });
        })
        .catch((err) => {
          dispatch({
            type: GET_USER_INFO_FAILURE,
            payload: { isLoadingGetUserInfo: false },
          });
        });
    }
  };
};

export const CheckUserAccStatus = () => {
  return (dispatch: any) => {
    const localStorageUser = isUserExistInLocalStorage();
    if (localStorageUser?.userId) {
      const requestData = {
        userId: localStorageUser?.userId,
      };
      axios
        .post(USER_ENDPOINT + "/CheckUserStatus", requestData)
        .then((res) => {
          if (!res?.data?.succeeded) {
            Logout();
            window.location.href = login;
          }
        })
        .catch((err) => {
          console.log(err, " err");
        });
    }
  };
};

export const UpdatePassword = (passwordRequest: any) => {
  return (dispatch: any) => {
    dispatch({
      type: UPDATE_PASSWORD_REQUEST,
      payload: { isLoadingUpdatePassword: true },
    });

    axios
      .post(USER_ENDPOINT + "/UpdatePassword", passwordRequest)
      .then((res) => {
        dispatch({
          type: UPDATE_PASSWORD_SUCCESS,
          payload: { isLoadingUpdatePassword: false },
        });
        dispatch(ShowSnackbar("Password updated successfully!", "success"));
        window.location.reload();
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_PASSWORD_FAILURE,
          payload: { isLoadingUpdatePassword: false },
        });
        dispatch(ShowSnackbar(err?.data?.Message, "error"));
      });
  };
};

export const isUserExistInLocalStorage = () => {
  if (localStorage.getItem("currentUser")) {
    const currentLoginUser = JSON.parse(
      localStorage.getItem("currentUser") || ""
    );
    return currentLoginUser;
  } else {
    return {};
  }
};

export function Logout() {
  localStorage.clear();
}

export const GetAgentRequest = (userId: any) => {
  return (dispatch: any) => {
    dispatch({
      type: GET_AGENT_REQUEST,
      payload: { isLoadingGetAgentRequest: true },
    });

    axios
      .get(`${USER_ENDPOINT}/DownlineList?userId=${userId}`)
      .then((res) => {
        dispatch({
          type: GET_AGENT_SUCCESS,
          payload: {
            agentRequestData: res?.data,
            isLoadingGetAgentRequest: false,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_AGENT_FAILURE,
          payload: {
            isLoadingGetAgentRequest: false,
          },
        });
      });
  };
};
